nav {
  display: block;
  text-align: center;
}
nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.nav a {
  display: block;
  /* background: #111;  */
  color: #454141;
  text-decoration: none;
  padding: 0.8em 1.8em;
  /* text-transform: uppercase; */
  /* font-size: 80%; */
  letter-spacing: 1.1px;
  /* text-shadow: 0 -1px 0 #000; */
  position: relative;
  font-size: 0.9rem;
  /* font-family: "SF Pro Rounded", -apple-system, BlinkMacSystemFont, Roboto,
    "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol"; */
  font-family: "Fira Sans", sans-serif;
  font-weight: 600;
}
.nav {
  /* vertical-align: top;  */
  display: inline-block;
  /* box-shadow: 
    1px -1px -1px 1px #000, 
    -1px 1px -1px 1px #fff, 
    0 0 6px 3px #fff;
  border-radius:6px; */
}
.nav li {
  position: relative;
}
.nav > li {
  float: left;
  /* border-bottom: 4px #aaa solid;  */
  /* margin-right: 1px;  */
}
.nav > li > a {
  /* margin-bottom: 1px;
  box-shadow: inset 0 2em .33em -0.5em #555;  */
}
.nav > li:hover,
.nav > li:hover > a {
  /* border-bottom-color: orange; */
}
.nav li:hover > a {
  color: #ff5c31;
}
.nav > li:first-child {
  /* border-radius: 4px 0 0 4px; */
}
.nav > li:first-child > a {
  /* border-radius: 4px 0 0 0; */
}
.nav > li:last-child {
  /* border-radius: 0 0 4px 0; 
  margin-right: 0; */
}
.nav > li:last-child > a {
  /* border-radius: 0 4px 0 0; */
}
.nav li li a {
  margin-top: 1px;
}

/* .nav li a:first-child:nth-last-child(2):before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  border: 5px solid transparent;
  top: 50%;
  right: 5px;
} */

/* submenu positioning*/
.nav ul li a {
  text-align: left;
  font-weight: 400;
}
.nav ul {
  position: absolute;
  white-space: nowrap;
  /* border-bottom: 5px solid  orange; */
  z-index: 1;
  left: -99999em;
  box-shadow: 0px 1px 5px #ddd;
  background: #fff;
}
.nav ul li {
  margin: 4px;
}
.nav > li:hover > ul {
  left: auto;
  margin-top: 0px;
  min-width: 100%;
}
.nav > li li:hover > ul {
  left: 100%;
  margin-left: 1px;
  top: -1px;
}
.nav > li li a:hover {
  background-color: #e3ebfc;
}
/* arrow hover styling */
/* .nav > li > a:first-child:nth-last-child(2):before {
  border-top-color: #454141;
} */
/* .nav > li:hover > a:first-child:nth-last-child(2):before {
  border: 5px solid transparent;
  border-bottom-color: #ff5c31;
  margin-top: -5px;
} */
/* .nav li li > a:first-child:nth-last-child(2):before {
  border-left-color: #ff5c31;
  margin-top: -5px;
} */
/* .nav li li:hover > a:first-child:nth-last-child(2):before {
  border: 5px solid transparent;
  border-right-color: #ff5c31;
  right: 10px;
} */


.nav li a span {
  position: absolute;
  top:10px;
  right:0px
}
.nav li:hover a span {
  position: absolute;
  top:6px;
  transform: rotate(180deg);
}
