* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* body {
  background-color: #ddd;
} */
.button {
  padding: 8px 15px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}
a {
  color: #464141;
  text-decoration: none;
}
.slider-control-bottomcenter {
  display: none;
}
/* .slide-current {
 border: 5px solid red;
} */

img {
  max-width: 100%;
}
.MuiTimelineItem-missingOppositeContent:before {
  flex: 0 !important;
}

/* .appBar {
  background: #fff;
  height: 60px;
  margin-top: 0;
  padding: 0;
  color: #999;
  box-shadow: 0;
} */
